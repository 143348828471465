/* eslint-disable radix */
import cookies from 'js-cookie';
import { expiredToken } from '@config';

export const setLoginEcatalog = (isLogin = 0, expired) => {
    cookies.set(
        'isLoginEcatalog',
        isLogin,
        {
            expires: expired || expiredToken,
            sameSite: 'none',
            secure: true,
        },
    );
    return 0;
};

export const getLoginInfoEcatalog = () => {
    const isLogin = cookies.get('isLoginEcatalog');
    return parseInt(isLogin) || 0;
};

export const removeIsLoginFlaggingEcatalog = () => {
    cookies.remove('isLoginEcatalog', { sameSite: 'none', secure: true });

    // add remove cookies on header and next-cookies
    // base on https://www.npmjs.com/package/next-cookies
    document.cookie = 'foo=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
};
